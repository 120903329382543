<template>
    <div class="promotion-container">
        <div class="promotion-title">Promotion Link</div>
        <div class="promotion-content">
            <div class=" flex items-center ">
                <span class=" block sm:inline-block w-full sm:w-auto">专属推广链接：</span>
                <a
                    ref="hrefLink"
                    @click="handleCopy"
                    :data-clipboard-text="brokerShareUrl"
                    >{{brokerShareUrl}}</a
                >
                <img
                ref="hrefImg"
                :data-clipboard-text="brokerShareUrl"
                @click="copyClick"
                class=" w-6 cursor-pointer block ml-4" :src="require('@/assets/icon/copy.png')" alt="">
            </div>
            <div style="margin-top: 30px">
                <span>专属推广二维码：</span>
                  <img
                  class=" m-auto mt-4"
                  ref="qrCode"
                  />
            </div>
            <div class=" flex  justify-center">
              <button
              v-show="this.code"
              @click="clickDownloadQr"
              class=" font-500 text-base underline">download</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Clipboard from 'clipboard'
import QrCodeWithLogo from 'qr-code-with-logo'
import logoPng from '@/assets/img/square_logo.png'

export default {
  mounted () {
    if (this.code !== null) {
      QrCodeWithLogo.toImage({
        image: this.$refs.qrCode,
        content: this.brokerShareUrl,
        width: 260,
        logo: {
          src: logoPng,
          radius: 8
        }
      })
    }
  },
  methods: {
    clickDownloadQr () {
      QrCodeWithLogo.toImage({
        content: this.brokerShareUrl,
        width: 260,
        downloadName: 'NZsky_promote_QR',
        logo: {
          src: logoPng,
          radius: 8
        },
        download: true
      })
    },
    copyClick () {
      this.$refs.hrefLink.click()
    },
    handleCopy () {
      var clipboard = new Clipboard(this.$refs.hrefLink)
      clipboard.on('success', e => {
        this.$message.success('复制成功')
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        this.$message.warning('该浏览器不支持自动复制')
        clipboard.destroy()
      })
    }
  },
  components: {
  },
  data () {
    return {
      tabs: ['Account', 'Security'],
      currentTab: 'Account',
      transition_name: 'swipe-left'
    }
  },
  computed: {
    brokerShareUrl () {
      if (this.code === null) {
        return ''
      } else {
        return `https://${window.location.host}/sms-login?id=${this.code}`
      }
    },
    ...mapState('broker', ['code']),
    ...mapState('login', ['status', 'attributes'])
  }
}
</script>

<style scoped>
.promotion-title {
    padding: 30px;
    background: #ffffff;
    font-size: 24px;
    font-weight: 400;
    color: #080808;
    line-height: 28px;
}
.promotion-content {
    margin: 30px 0;
    padding: 10px 30px;
}
@media only screen and (max-width: 600px) {
    .promotion-content {
        margin: 20px;
        padding: 20px 10px;
    }
}
</style>
